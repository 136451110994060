<!-- 关联商品 -->

<template>
  <div class="relatedProducts">
    <back />
    <div class="globle_border" v-loading="loading">
      <!-- 顶部搜索 -->
      <div class="search">
        <el-input v-model="barCode" placeholder="请搜索商品条形码" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
        <el-button class="btn" type="primary" @click="handleOpen">新增</el-button>
      </div>

      <!-- 表格 -->
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column label="序号" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="brand" label="品牌"></el-table-column>
          <!-- <el-table-column min-width="150" show-overflow-tooltip prop="serialId" label="商品编号"></el-table-column> -->
          <el-table-column min-width="150" show-overflow-tooltip prop="barCode" label="商品条形码"></el-table-column>
          <el-table-column min-width="150" show-overflow-tooltip prop="productName" label="商品名称"></el-table-column>
          <el-table-column min-width="100" show-overflow-tooltip prop="specs" label="规格"></el-table-column>
          <el-table-column min-width="130" show-overflow-tooltip prop="categoryName" label="分类"></el-table-column>
          <el-table-column label="操作" header-align="center" align="center">
            <template slot-scope="scope">
              <el-button size="small" class="btn" type="primary" plain @click="handleCheck(scope.row)">查看</el-button>
              <el-button size="small" class="btn" type="danger" plain @click="handleDelete(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

    </div>

    <!-- 分页 -->
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
      layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
    </el-pagination>

    <!-- 选择商品弹窗 -->
    <SelectDialog ref="sonComponent" :show="selectDialogShow" @cancelSelect="cancelSelect" @handleSubmit="handleSubmit"
      @handleOpen="handleOpen"></SelectDialog>

  </div>
</template>

<script>
import SelectDialog from '../components/selectDialog.vue';
export default {
  components: { SelectDialog },
  data() {
    return {
      loading: false,
      id: null,
      barCode: '',
      // 表格数据
      tableData: [],
      // 选择商品弹窗
      selectDialogShow: false,
      checkData: [], // 选择的数据
      // 分页
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created() {
    this.id = this.$route.query.id
    this.getReleatedProductList();
  },
  methods: {
    // 获取商品列表
    getReleatedProductList() {
      this.loading = true;
      this.$axios.get(this.$api.getReleatedProductList, {
        params: {
          id: this.id,
          barCode: this.barCode || null,
          shopId: this.$store.state.userInfo.shopId, //shopId
          page: this.currentPage,
          pageSize: this.pageSize,
        }
      }).then((res) => {
        if (res.data.code == 100) {
          this.tableData = res.data.result.list;
          this.totalItemsCount = res.data.result.totalCount;
        }
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    },
    // 打开弹窗
    handleOpen() {
      this.selectDialogShow = true
      this.$refs.sonComponent.retrieveTwoInterface();
    },
    // 关闭选择商品弹窗
    cancelSelect() {
      this.selectDialogShow = false;
    },
    // 选择商品弹窗 确定
    handleSubmit(data) {
      console.log(data);
      let addList = data.map(item => {
        return {
          productId: item.productId,
          shopId: item.shopId,
          templateId: this.id,
          specs: item.specs
        }
      })
      this.selectDialogShow = false;
      this.$axios.post(this.$api.addFreightProduct, addList
      ).then((res) => {
        if (res.data.code == 100) {
          this.$message.success('新增成功！')
          this.getReleatedProductList()
        }
      })

    },
    handleCheck(row) {
      this.$router.push({ path: '/product/detail/info', query: { id: row.shopProductId } })
    },
    handleDelete(row) {
      this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        this.$axios.post(`${this.$api.deleteFreightTemplateProduct}/${row.id}`)
          .then((res) => {
            if (res.data.code == 100) {
              this.$message.success(res.data.desc)
            }
            this.getReleatedProductList()
          })
      }).catch(() => { });
    },
    // 清空
    clearData() {
      this.tableData = [];
      this.currentPage = 1;
    },
    // 搜索
    onSearch() {
      this.clearData();
      this.getReleatedProductList();
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.clearData();
      this.pageSize = e;
      this.getReleatedProductList();
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = [];
      this.currentPage = e;
      this.getReleatedProductList();
    },

  }

}



</script>

<style scoped lang="scss">
.relatedProducts {

  // 顶部搜索
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 250px;
    }

    .el-input,
    .el-select,
    .el-button {
      margin-left: 20px;
    }

  }
}
</style>
