<template>
  <!-- 弹框 -->
  <el-dialog title="新增商品" :visible.sync="show" :before-close="cancelSelect">

    <!-- 顶部搜索 -->
    <div class="search">
      <el-input v-model="productValue" placeholder="请搜索商品名称" suffix-icon="el-icon-search" @change="onSearch"
        clearable></el-input>
      <el-cascader v-model="categoryValue" :options="categoryListData" placeholder="请选择分类"
        :props="{ expandTrigger: 'hover', label: 'categoryName', value: 'categoryId', emitPath: false, checkStrictly: true }"
        @change="onSearch" clearable></el-cascader>
    </div>

    <div class="globle_table">
      <el-table :data="tableData" height="450" style="width: 100%;" @selection-change="handleSelectionChange"
        highlight-current-row>
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column label="序号" width="80" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
          </template>
        </el-table-column>
        <!-- <el-table-column show-overflow-tooltip prop="serialId" label="商品编号"></el-table-column> -->
        <el-table-column min-width="120" show-overflow-tooltip prop="barCode" label="商品条形码"></el-table-column>
        <el-table-column min-width="120" show-overflow-tooltip prop="productName" label="商品名称"></el-table-column>
        <el-table-column min-width="120" show-overflow-tooltip prop="brand" label="品牌"></el-table-column>
        <el-table-column min-width="120" show-overflow-tooltip prop="factoryName" label="厂家"></el-table-column>
        <el-table-column min-width="120" show-overflow-tooltip prop="categoryName" label="分类"></el-table-column>
        <el-table-column min-width="120" show-overflow-tooltip prop="specs" label="规格"></el-table-column>
      </el-table>
    </div>
    <div class="pagebox">
      <el-pagination background @current-change="handleCurrentChange" @size-change="handleSizeChange"
        :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
        layout="prev, pager, next, jumper,sizes, total" :total="totalItemsCount">
      </el-pagination>
    </div>
    <span slot="footer">
      <el-button @click="cancelSelect">取 消</el-button>
      <el-button type="primary" @click="handleSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      productValue: '', // 搜索商品名称
      categoryValue: '', // 搜索分类名称
      tableData: [],
      //   当前选择的行的数据
      checkList: [],
      categoryListData: [],
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0,
    };
  },
  created() {
    // this.getFreightProductList();
    // this.getCategoryInfoList();
  },
  mounted() { },
  methods: {
    // 获取列表
    getFreightProductList() {
      this.loading = true;
      this.$axios.get(this.$api.getFreightProductList, {
        params: {
          productName: this.productValue || null, // 商品名称
          categoryId: this.categoryValue || null, // 分类id
          shopId: this.$store.state.userInfo.shopId, //shopId
          page: this.currentPage,
          pageSize: this.pageSize,
        }
      }).then((res) => {
        if (res.data.code == 100) {
          this.tableData = res.data.result.list;
          this.totalItemsCount = res.data.result.totalCount;
        }
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    },
    // 获取分类
    getCategoryInfoList() {
      this.$axios.get(this.$api.getCategoryInfoList, {
        params: {
          auditStatus: '1',
          page: 1,
          pageSize: 9999,
        }
      }).then((res) => {
        if (res.data.code == 100) {
          this.categoryListData = res.data.result.list;
        }
      })
    },
    // 重置
    resetData() {
      this.checkList = [];
      this.productValue = null;
      this.categoryValue = null;
      this.clearData();
    },
    // 调取两个接口
    retrieveTwoInterface() {
      this.getFreightProductList();
      this.getCategoryInfoList();
    },
    // 取消
    cancelSelect() {
      this.$emit("cancelSelect");
      this.resetData();
    },
    // 确定
    handleSubmit() {
      this.$emit("handleSubmit", this.checkList);
      this.resetData();
    },
    // 选择数据
    handleSelectionChange(val) {
      this.checkList = val;
    },
    // 清空
    clearData() {
      this.tableData = [];
      this.currentPage = 1;
    },
    // 搜索
    onSearch() {
      this.clearData();
      this.getFreightProductList();
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.pageSize = e;
      this.onSearch();
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = [];
      this.currentPage = e;
      this.getFreightProductList();
    },
  }
};
</script>

<style lang="scss" scoped>
.pagebox {

  /deep/ .el-input,
  /deep/ .el-input__inner {
    width: 90px;
  }

}

.search {
  margin-bottom: 20px;
  display: flex;

  /deep/.el-input {
    width: 255px;
    margin-right: 20px;
  }
}
</style>